import React from "react";
import withRoleRequired from '../../lib/withRoleRequired';
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import IndexCard from "../../components/IndexCard";

const IndexPage: React.FunctionComponent = () => (
  <Layout 
    pageHeader="Workforce Population Modelling"
    moreInfo="modelling"
  >
    <SEO
      keywords={[`dstl`, `future`, `workforce`, `modelling`]}
      title="Home"
    />
    <div className="max-w-6xl px-4 py-4 mx-auto md:px-8 md:py-4">
      <div className="mb-8">
        This element of the tool includes a number of interactive data visualisations which enable you to explore the output of population modelling that was carried out as part of the research project. They show various demographic projections for the UK population between 2019 and 2040.
      </div>
      <section>
        <div className="grid grid-cols-1 lg:grid-cols-1 gap-4">
          <IndexCard imageSource="/image/visualisations/line.svg" link="/modelling/visualisation-one" title="Population Projections" description="This visualisation allows you to produce line chart projections over time for different sections of the UK population based on ethnicity, age, gender, nationality, religion, health, qualification level." />
          <IndexCard imageSource="/image/visualisations/pyramid.svg" link="/modelling/visualisation-two" title="Population Pyramids" description="This visualisation displays population pyramids for different sections of the population based on ethnicity and geographic region." />
          <IndexCard imageSource="/image/visualisations/dot.svg" link="/modelling/visualisation-three" title="Population Demography by Region and Age Band" description="This visualisation gives a breakdown of the entire UK population represented by different age bands and presented by geographical region." />
        </div>
      </section>
    </div>
  </Layout>
)

export default withRoleRequired(IndexPage);
